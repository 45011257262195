// i18next-extract-mark-ns-start pos-for-freelancers

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {OrderedList} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import styled from 'styled-components';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore, IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AndroidDownloadLink, DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {InternalPageLink, PricingLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import cards from 'images/credit-cards.svg';
import mobile_payment from 'images/mobile_payment.svg';
import money from 'images/money.svg';
import cart2 from 'images/cart2.svg';
import megaphone from 'images/megaphone.svg';
import person from 'images/person.svg';
import {SignUpLink} from 'components/links/Other';
import {StyledDiv} from 'components/StyledSx';
import {Background} from 'components/Background';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {RotatedScreenshots} from 'components/monei-pay/RotatedScreenshots';
import {SupportLink} from 'components/links/Support';
import {BlogLink} from 'components/links/Blog';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {ButtonLink} from 'components/Button';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const RetailPosSytem: React.FC<PageProps> = (props) => {
  const {t} = useI18next();

  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        title={t('POS for Freelancers')}
        description={t(
          'Use a POS for freelancers to charge your clients from your phone. Accept many payment methods and centralize your payment data in one app. Start now ››'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>The most flexible POS for freelancers</Trans>
              </SectionHeader>
              <Trans parent="p">
                Regardless of the services you offer as a freelancer, physical payments are always a
                time and money consuming part of the job. Whether you have a fixed business location
                or you work on the go, the right mobile payment app let’s you ditch the outdated and
                expensive traditional POS and use your phone to accept instant payments anywhere.
              </Trans>
              <Trans parent="p">
                Are you a personal trainer, an accountant, a performer, or a builder? Save time and
                money, and gain flexibility with MONEI Pay.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay Now</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{marginTop: '10px', textAlign: 'center'}}>
              <Trans>
                The best POS for freelancers: the benefits of taking payments from your phone
              </Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                With MONEI Pay’s POS for freelancers, you’ll never need traditional POS hardware
                again. Use your smartphone or another mobile device to collect instant payments from
                your clients on location or on the go.
              </Trans>
            </p>
            <p style={{textAlign: 'center'}}>
              <Trans>
                Avoid the hassle of bulky and expensive POS hardware that runs out of battery power,
                requires paper, and fails when you need it most. Instead, use a device you already
                own and carry all the time — turn your phone into a mobile POS.
              </Trans>
            </p>
            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <CardWrapper>
                  <Trans>
                    Accept many{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>:{' '}
                    <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      Google Pay
                    </InternalPageLink>
                    , and <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -48}} />
                <CardWrapper>
                  <Trans>
                    Save up to 50% on <PricingLink>transaction fees</PricingLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={megaphone} alt="" style={{top: -40, width: 100}} />
                <CardWrapper>
                  <Trans>
                    Build brand awareness and trust —{' '}
                    <SupportLink slug="/articles/8797620433937">customize</SupportLink> your mobile
                    payment page
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cart2} alt="" style={{top: -55, width: 100}} />
                <CardWrapper>
                  <Trans>Charge clients from anywhere, at any time</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
                <CardWrapper>
                  <Trans>Process payments instantly</Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -60, width: 90}} />
                <CardWrapper>
                  <Trans>
                    Offer a{' '}
                    <BlogLink slug="frictionless-payments">
                      frictionless payment experience
                    </BlogLink>
                  </Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download your mobile payment app for freelancers today</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>
      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay for freelancers work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                Taking payments for your{' '}
                <BlogLink slug="freelancer-payment-methods">freelance business</BlogLink> with MONEI
                Pay is simple.
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Create your MONEI account</SignUpLink>
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the MONEI Pay
                  app to your smartphone or tablet
                </Trans>
                <Trans parent="li">
                  Enter the invoice amount to{' '}
                  <SupportLink slug="/articles/4417709618193">
                    create digital QR code payments
                  </SupportLink>{' '}
                </Trans>
                <Trans parent="li">Have your client scan the QR code to pay</Trans>
                <Trans parent="li">
                  Client selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">Client finishes the payment with the tap of a button</Trans>
                <Trans parent="li">Funds are instantly deposited into your account</Trans>
              </OrderedList>
              <Trans parent="p">
                If your client isn’t present, you can also use MONEI pay to{' '}
                <BlogLink slug="email-payment-link">send payment links via email</BlogLink>, SMS, or
                WhatsApp with{' '}
                <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Streamline your freelance payments with MONEI Pay</Trans>
            </SectionHeader>
            <Trans parent="p">
              What are you waiting for? Open your MONEI account today. Then download the{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on as many devices as you
              need to start generating digital QR code payments for your freelance business.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan any QR code to test the payment experience.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '100px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for freelancers?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your users accept more payment
              methods in person or online.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
    </>
  );
};

export default RetailPosSytem;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "pos-for-freelancers"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
